import React from "react";
import { Helmet } from "react-helmet"
import StandardLayout from "../layout/StandardLayout";

const Stevedoring = () => {
    return <StandardLayout>

        <Helmet>
            <title>Stevedoring - Burke Shipping Services</title>
        </Helmet>

        <div className="c-hero" style={{backgroundImage: "url(/images/shipping-hero-4.jpg)"}} ></div>

        <div className="u-fill-1 u-pad-large u-pad-xlarge@m">
            <div className="l-wrap u-width-5 u-align-center">
                <h1 className="c-heading +h1 u-marg-bottom">Stevedoring</h1>
                <p className="u-font-large@m">
                    Burke Shipping Services Ltd provide stevedoring services for a range of bulk and breakbulk cargoes. Our stevedores are experienced in handling a wide range of cargo types, operating a safe, efficient and excellent stevedoring service. We have a range of machinery and a knowledgeable team to ensure all cargoes are handled safely.
                </p>
            </div>
        </div>

        <div className="l-wrap">
            <div className="c-media-text +background-image-fill">
                <div className="c-media-text__contents +contents-flipped">
                    <div className="c-media-text__a">
                        <div className="u-pad-top-bottom-xlarge@m">

                            <p className="u-font-large@m u-marg-bottom u-marg-bottom-large@m">Our team are experienced in dealing with specialist heavy lift and project cargoes as well as some of the following cargo:</p>

                            <div className="l-grid +cols-1 +cols-2@m">
                                <div className="l-grid__item">
                                    <h3 className="c-heading +h3 u-marg-bottom">Bulk</h3>
                                    <ul>
                                        <li>Grains</li>
                                        <li>Agri-Products</li>
                                        <li>Coal</li>
                                        <li>Fertiliser</li>
                                        <li>Minerals</li>
                                        <li>Glass Cullet</li>
                                        <li>Stone</li>
                                        <li>Iron Ore</li>
                                    </ul>
                                </div>
                                <div className="l-grid__item">
                                    <h3 className="c-heading +h3 u-marg-bottom">Breakbulk</h3>
                                    <ul>
                                        <li>Plywood</li>
                                        <li>Packaged Timber</li>
                                        <li>Steel Pipes</li>
                                        <li>Steel Rebar</li>
                                        <li>Project Cargo/Heavy Lifts</li>
                                        <li>Wind Turbines/Gas Turbines</li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="c-media-text__b">
                        <div className="c-media-text__media">
                            <img src="/images/shipping-1.jpg" alt="Ship and Port Cranes"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="u-fill-2 u-color-1 u-pad-large u-pad-xlarge@m">
            <div className="l-wrap u-align-center">
                <p className="u-font-large@m">For further information regarding our
                    stevedoring operations and rates please email <a href="mailto:agency@burkeshipping.co.uk" className="u-color-1 u-font-bold">agency@burkeshipping.co.uk</a> or <a href="/contact" className="u-color-1 u-font-bold">Contact Us</a></p>
            </div>
        </div>
    </StandardLayout>
};

export default Stevedoring;